import React from 'react';
import styled from 'styled-components';
import { LocationProductEnum } from '../../../graphql/query/locationsByOrgId';
import { useMyProfileQuery } from '../../../graphql/query/userProfile';
import { DashboardFilter } from '../../../pages/Dashboard/hooks/useFilters';
import { Spacer } from '../Spacer';
import { SocialAccount } from '../VehicleColumn/modeViews/VehicleSocialAccounts';
import { CarsForSaleMetricsGraphs } from './components/CarsForSaleMetricsGraphs';
import { GraphPost } from './components/GraphPost';
import { PopularPost } from './components/PopularPost';
import { SoldCars } from './components/SoldCars';
import { SummaryPost } from './components/SummaryPost';
import { CampaignReport } from './components/CampaignReport';
import { MarketplaceLeaderboard } from './components/MarketplaceLeaderboard';
import { LeadRevivalReport } from './components/LeadRevivalReport';
import { TopMarketplaceLeads } from './components/TopMarketplaceLeads';

const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
`;
const LeftWrapper = styled.div`
  margin-right: 12px;
  width: 70%;
`;

const RightWrapper = styled.div`
  margin-left: 12px;
  width: 30%;
`;

const SummaryWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const ChartWrapper = styled.div`
  display: grid;
`;

const BottomGraphWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
`;

interface DashBoardContentProps {
  dashboardFilters: DashboardFilter;
}
const ACCOUNT_CARDS: SocialAccount[] = [
  SocialAccount.Facebook,
  SocialAccount.FacebookMarketplace,
  SocialAccount.Google,
  SocialAccount.Instagram,
  SocialAccount.TikTok,
];
const isForbiddenProduct = (allowedProducts: LocationProductEnum[], product: SocialAccount) => {
  return !allowedProducts.includes(product as unknown as LocationProductEnum);
};
const DashboardContent: React.FC<DashBoardContentProps> = ({ dashboardFilters }) => {
  const { data, loading } = useMyProfileQuery();
  const allowedProducts = data?.myInfo.products ?? [];
  const hasMarketplace = allowedProducts.includes(LocationProductEnum.FbMarketplace);

  return !loading ? (
    <DashboardWrapper>
      <LeftWrapper>
        <SummaryWrapper>
          {ACCOUNT_CARDS.map((accountCard) => {
            return (
              <>
                <SummaryPost
                  platform={accountCard}
                  dashboardFilters={dashboardFilters}
                  forbidden={isForbiddenProduct(allowedProducts, accountCard)}
                />
                <Spacer type="inline" size="s"></Spacer>
              </>
            );
          })}
        </SummaryWrapper>
        <Spacer type="stack" size="s" />
        <ChartWrapper>
          <CampaignReport dashboardFilters={dashboardFilters} />
        </ChartWrapper>
        <Spacer type="stack" size="s" />
        <ChartWrapper>
          <LeadRevivalReport dashboardFilters={dashboardFilters} />
        </ChartWrapper>
        <Spacer type="stack" size="s" />
        <ChartWrapper>
          <MarketplaceLeaderboard dashboardFilters={dashboardFilters} />
        </ChartWrapper>
        <Spacer type="stack" size="s" />
        <BottomGraphWrapper>
          <ChartWrapper>
            <GraphPost dashboardFilters={dashboardFilters} />
          </ChartWrapper>
          <ChartWrapper>
            <CarsForSaleMetricsGraphs dashboardFilters={dashboardFilters} />
          </ChartWrapper>
        </BottomGraphWrapper>
      </LeftWrapper>
      <RightWrapper>
        {hasMarketplace ? (
          <TopMarketplaceLeads dashboardFilters={dashboardFilters} />
        ) : (
          <SoldCars dashboardFilters={dashboardFilters} />
        )}

        <Spacer type="stack" size="s" />
        <PopularPost dashboardFilters={dashboardFilters} />
      </RightWrapper>
    </DashboardWrapper>
  ) : (
    <div></div>
  );
};

export default DashboardContent;
